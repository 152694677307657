// Core
import React from 'react';
import PropTypes from 'prop-types';

// Style
import './styles.scss'

const Banner = (props) => {
  const { title, desc, ...rest } = props;
  return (
    <div className='__banner_commn'>
        <div className='_bnr__top_gradient'></div>
        <h1>{title}</h1>
        {desc && <p>{desc}</p>}
    </div>
  );
};

export default Banner;

Banner.propTypes = {
  title: PropTypes.string,
};
