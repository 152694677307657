// Core
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// components

// Styles
import './styles.scss';

// Assets
import Playstore from 'assets/images/play-store-round.png'
import Appstore from 'assets/images/app_store_round.png'



import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import Button from 'components/commonComponents/Button';
import { Spin } from 'antd';
import { APP_ID } from 'config/constants';
import { Fade } from 'react-reveal';
import Banner from 'components/commonComponents/Banner';

const About = () => {
    const { currentLang } = useSelector((store) => store.commonData);
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [aboutData, setAboutData] = useState({});
    const [electronicData, setElectronicData] = useState({});
    const [founderData, setFounderData] = useState({});
    const [evApps, setEVApps] = useState([])
    const { t } = useTranslation();

    const fetchAboutData = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.getEVApps(currentLang?.id, APP_ID));
        setAboutData(res.applications[0]);
        setLoading(false)
    };

    const fetchElectronicVillage = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.getEVApps(currentLang?.id, 31));
        setElectronicData(res.applications[0]);
        setLoading(false)
    };

    const fetchFounder = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.getFounderInfo(currentLang?.id));
        setFounderData(res.founder[0]);
        setLoading(false)
    };

    const fetchEVApps = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.getEVApps(currentLang?.id));
        setEVApps(res.applications);
        setLoading(false)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchAboutData();
        fetchElectronicVillage();
        fetchFounder();
        fetchEVApps();
    }, [currentLang]);

    const handleApp = (id) => {
        navigate('/about/app/' + id)
    }
    const handleFounder = () => {
        navigate('/the-founder');
    }


    return (
        <>

            <Banner title={t('about_us')}/>

            <div className='container __innerTopPad' style={{ minHeight: '50vh' }}>
                {
                    isLoading ?
                        <div className='text-center'>
                            <Spin className='__spin_color' size="large" tip="Loading" />
                        </div>
                        :
                        <>
                            <Fade bottom cascade>
                                <div className='row'>
                                    <div className='col-md-3 mb-5'>
                                        <img src={aboutData?.applicationIcon} alt="" width={'100%'} height={'200px'} style={{ objectFit: 'contain' }} />
                                    </div>
                                    <div className='col-md-9 mb-5'>
                                        <h4 style={{ color: '#a19c74', }}>{aboutData?.tagline}</h4>
                                        <h1 className='__common-head' style={{ color: '#4095FF', fontSize: '2rem' }}>{aboutData?.name}</h1>
                                        <p dangerouslySetInnerHTML={{ __html: aboutData?.description }} style={{ lineHeight: '2', fontSize: '17px' }}></p>
                                    </div>
                                    <div className='col-md-9 mb-5'>
                                        <h4 style={{ color: '#a19c74', }}>{electronicData?.tagline}</h4>
                                        <h1 className='__common-head' style={{ color: '#8c5c3e', fontSize: '2rem' }}>{electronicData?.name}</h1>
                                        <p dangerouslySetInnerHTML={{ __html: electronicData?.description }} style={{ lineHeight: '2', fontSize: '17px', marginTop: '14px' }}></p>
                                    </div>
                                    <div className='col-md-3 mb-5'>
                                        <img src={electronicData?.applicationIcon} alt="" width={'100%'} height={'200px'} style={{ objectFit: 'contain' }} />
                                    </div>
                                    <div className='col-md-3 mb-5'>
                                        <img src={founderData?.logo} alt="" width={'100%'} height={'200px'} style={{ objectFit: 'contain' }} />
                                    </div>
                                    <div className='col-md-9 mb-5'>
                                        <h4 style={{ color: '#a19c74', }}>{t('founder')}</h4>
                                        <h1 className='__common-head' style={{ color: '#8bc34a', fontSize: '2rem' }}>{founderData?.name}</h1>
                                        <p style={{ lineHeight: '2', fontSize: '17px' }} className="__inr_p" dangerouslySetInnerHTML={{ __html: founderData?.description }}></p>
                                        <Button
                                            label={t('more')}
                                            withLoader
                                            style={{ margin: '0', marginTop : '20px' }}
                                            handleOnClick={handleFounder}
                                        />
                                    </div>
                                </div>
                            </Fade>
                            <h1 className='__common-head mt-4'>{t('other_ev_apps')}</h1>
                            <div className='__class_apps_set'>

                                {evApps && evApps.map((item, idx) => {
                                    return (
                                        <Fade left cascade>
                                            <div className='__single_app' key={idx}>
                                                <div className="d-flex flex-column justify-content-center align-items-center text-center" onClick={() => handleApp(item.appId)}>
                                                    <img src={item?.applicationIcon} />
                                                    <h1>{item?.name}</h1>
                                                </div>
                                                <div className="__apps">
                                                    <a href={item?.appStoreLink} target="_blank" ><img src={Appstore} alt="appstore" /></a>
                                                    <a href={item?.googleStoreLink} target="_blank"><img src={Playstore} alt="playstore" /></a>
                                                </div>
                                            </div>
                                        </Fade>
                                    )
                                })}
                            </div>
                        </>
                }
            </div>

        </>
    );
};

export default About;
