
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import './style.scss';
import { Spin } from 'antd';
import { Fade } from 'react-reveal';
import Banner from 'components/commonComponents/Banner';

const Policy = (props) => {
  const { id } = props;
  const { currentLang } = useSelector((store) => store.commonData);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  const fetchContent = async () => {
    setLoading(true)
    const res = await fetchData(restAPIs.getEVPages(currentLang?.id, id));
    setData(res.page);
    setLoading(false)
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    fetchContent();
  }, [currentLang, id]);
  return (
    <>
    <Banner title={data && data?.name} />
    <div className="container __innerTopPad">
      <div className="static-page">
        <Fade bottom cascade>
          <div className="privacy">
            {isLoading ?
              <div className='text-center' >
                <Spin className='__spin_color' size="large" tip="Loading" />
              </div>
              :
              <>
                {/* <h1 className='__common_page_head'>{data?.name}</h1> */}
                <p dangerouslySetInnerHTML={{ __html: data?.description }}></p></>}
          </div>
        </Fade>
      </div>
    </div>
    </>
  );
};

export default Policy;
