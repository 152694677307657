// Core
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import Button from 'components/commonComponents/Button';

// Styles
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import TextArea from 'components/commonComponents/TextArea';
import { Alert, Modal } from 'antd';


const EditComment = (props) => {
  const { onClose, open, commentData, commentId, poemId } = props;
  const { t } = useTranslation();
  const [dataSet, setData] = useState({});
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { comment = commentData } = dataSet;
  const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false, severity: '' })

  const handleOnChange = (key) => (_value) => {
    if (_value) {
      setError({
        ...error,
        [key]: null
      });
    }
    setData({
      ...dataSet,
      [key]: _value
    });
  };


  const handleSave = async () => {
    const errors = {
      comment: !comment,
    };

    setError(errors);
    let isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;
    setIsLoading(true);
    const formData = new FormData();
    formData.append('action', 'edit');
    formData.append('poemId', poemId);
    formData.append('comment', comment);
    formData.append('commentId', commentId);
    const res = await fetchData(restAPIs.comment(formData));
    if (res.statusCode === 200) {
      setData({});
      setError({});
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'success' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
      }, 3000);
      onClose(true);
    } else {
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
      }, 3000);
    }
    setIsLoading(false);
  };

  const handleClose = (event, reason) => {
    setData({});
    setError({});
    setErrorAlert({})
    onClose(false);
  };


  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class'
        footer={null}
        closable={false}
      >
        <h3 className='____common_model_class_head' style={{ marginBottom: '10px' }}>
          {t('edit_comment')}
        </h3>
        <div className='.____common_model_class_desc'>
          <div className='mt-2'>
            <TextArea
              onChange={handleOnChange('comment')}
              value={comment}
              placeholder={t('enter_your_comment')}
              type='text'
              label={t('comment')}
              error={error.comment}
              height={110}
            />
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-end' style={{ marginTop: ' 15px', gridGap: '10px' }}>
          <a onClick={() => handleClose(false)} style={{ cursor: 'pointer'}}>{t('cancel')}</a>
          <Button
            withLoader
            loading={isLoading}
            disabled={isLoading}
            handleOnClick={handleSave}
            label={t('submit')}
            style={{ margin: '0' }}
          />
        </div>
        {errorAlert && errorAlert.open ?
          <Alert severity={errorAlert.severity} sx={{ width: '100%' }}>
            {errorAlert.msg}
          </Alert> : ''}
      </Modal>

    </>
  );
};

export default EditComment;

EditComment.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  commentData: PropTypes.any,
  commentId: PropTypes.any,
  movieId: PropTypes.any,
};
