// Core
import React from 'react';
import PropTypes from 'prop-types';
// Style
import './styles.scss';
import { useTranslation } from 'react-i18next';
import Highlight from '../Highlight';

const Poet = (props) => {
    const { item, searchkey, handleOnClick, ...rest } = props;
    const { t } = useTranslation();
    const highlightText = (text, highlight) => {
        const parts = text?.split(new RegExp(`(${highlight})`, 'gi'));
        let highlightedHTML = '';
        parts?.forEach((part, i) => {
            if (part.toLowerCase() === highlight.toLowerCase()) {
                highlightedHTML += `<span key=${i} style="font-weight: bold; background: #ffff0054;">${part}</span>`;
            } else {
                highlightedHTML += part;
            }
        });
        return highlightedHTML;
    };
    return (

        <div className="grid-academy__item" onClick={handleOnClick}>
            <div className="card-academy">
                <figure className="card-academy__figure">
                    <img className="card-academy__media" src={item?.poetPicture} alt={item?.fullName} />
                </figure>
                <div className="card-academy__info">
                    <div className="card-academy__header">
                        <div className="card-academy__row">
                            <h4>{item?.country || item?.city}</h4>
                            <h3 className="card-academy__title"><Highlight text={item?.fullName ? item?.fullName : ''} highlight={searchkey} /></h3>
                            {searchkey  ? <p className="card-academy__desc" dangerouslySetInnerHTML={{ __html: highlightText(item?.biography, searchkey) }}></p>
                             : <p className="card-academy__desc" dangerouslySetInnerHTML={{ __html: item?.biography }}></p>}
                            <a className="__anchor">{t('learn_more')}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Poet;

Poet.propTypes = {
    item: PropTypes.any,
    handleOnClick: PropTypes.func,
};
