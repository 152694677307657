import { APP_ID, BASE_URL, USER_HOST } from 'config/constants';

export const restAPIs = {
  login: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_login.php`
  }),
  signUp: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_userRegistration.php`
  }),
  changePassword: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_changePassword`
  }),
  changeProfPic: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_changeProfilePic`
  }),
  changeLanguage: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_changeLanguage`
  }),
  otpVerify: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_otpverify`
  }),
  forgotPassword: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_forgotPassword`
  }),
  resetPassword: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_resetPassword`
  }),
  changeName: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_changeUsername.php`
  }),
  getWallet: (body) => ({
    method: 'get',
    endpoint: `${USER_HOST}/wallet.php`
  }),
  getCountryList: (body) => ({
    method: 'get',
    endpoint: `${USER_HOST}/countryinfos.php`
  }),
  contactUs: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_contactus.php`
  }),
  changeEmail: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_changeEmail`
  }),
  changeEmailOTP: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_sendOtp`
  }),
  deleteAccount: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_deleteUser`
  }),

  getFavorites: (language) => ({
    method: 'get',
    endpoint: 'http://lehkhawla.electronicvillage.org/json_favourite_lovepoems.php'
  }),

  updateFavorite: (body) => ({
    method: 'post',
    body,
    endpoint: `http://lehkhawla.electronicvillage.org/json_fav_poem_form.php`
  }),

  etisalatEncryption: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/etisalat_encrypt.php`
  }),
  getAppInfo: (appId, language) => ({
    method: 'get',
    endpoint: `https://www.electronicvillage.org/json_appInfo.php?appId=${appId}&language=${language}`
  }),
  getFounderInfo: (language) => ({
    method: 'get',
    endpoint: `https://www.electronicvillage.org/json_founder.php?language=${language}`
  }),
  getEVApps: (language, appId=null) => ({
    method: 'get',
    endpoint: `${USER_HOST}/json_applications.php?language=${language}${appId? '&appId='+appId : ''}`
  }),
  getAstroInDetail: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_astro_indetail.php`
  }),
  translateData: (client, sl, tl, dt, q) => ({
    method: 'get',
    endpoint: `https://translate.googleapis.com/translate_a/single?client=${client}&sl=${sl}&tl=${tl}&dt=${dt}&q=${q}`
  }),
  getSubscriptions: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_subscriptions.php`
  }),
  subscriptionForm: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_subscription_form.php`
  }),
  encryption: (data) => ({
    method: 'post',
    endpoint: `${USER_HOST}/etisalat_encrypt.php?data=${data}`
  }),
  etisalatTerms: (pageId, language) => ({
    method: 'get',
    endpoint: `https://www.electronicvillage.org/json_evPages.php?pageId=${pageId}&language=${language}`
  }),
  simBilling: (body) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/etisalatsimbilling.php`
  }),
  getEVPages: (language, pageId) => ({
    method: 'get',
    endpoint: `https://www.electronicvillage.org/json_evPages.php?language=${language}&pageId=${pageId}`
  }),
  helpAndSupport: (language) => ({
    method: 'get',
    endpoint: `${USER_HOST}/json_appHelpSupport.php?appId=${APP_ID}&language=${language}`
  }),
  myWallet: (language) => ({
    method: 'get',
    endpoint: `https://www.electronicvillage.org/json_page.php?pageId=39&language=${language}`
  }),
  countryInfos: (language) => ({
    method: 'get',
    endpoint: `${USER_HOST}/countryinfos.php?language=${language}`
  }),
  unSubscribe: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/unsubscribe.php`,
  }),
  getPoetList: (language, poetId, page, searchText, searchOption) => ({
    method: 'get',
    endpoint: `${BASE_URL}/json_PoetsList.php?language=${language}${poetId ? `&poetId=${poetId}` : ''}${page ? `&page=${page}` : ''}${searchText ? `&searchText=${searchText}` : ''}${searchOption ? `&searchOption=${searchOption}` : ''}`
  }),
  getPoem: (language, poemId) => ({
    method: 'get',
    endpoint: `${BASE_URL}/json_Poem.php?language=${language}&poemId=${poemId ? poemId : ''}`
  }),
  getPoemsList: (language, poetId, letterId) => ({
    method: 'get',
    endpoint: `${BASE_URL}/json_PoemsList.php?language=${language}${poetId ? `&poetId=${poetId}` : ''} ${letterId ? `&letterId=${letterId}` : ''}`
  }),

  getLettersList: (language) => ({
    method: 'get',
    endpoint: `${BASE_URL}/json_LettersList.php?language=${language}`
  }),

  comment: (body) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/json_poem_comment_form.php`
  }),

  commentList: (poemId, page) => ({
    method: 'get',
    endpoint: `${BASE_URL}/json_poem_comments.php?poemId=${poemId}&page=${page}`
  }),

  favourites: () => ({
    method: 'get',
    endpoint: `${BASE_URL}/json_favourite_hawaya.php`
  }),

  addFavourite: (body) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/json_favourite_hawaya.php`
  }),

  likePoem: (body) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/json_poem_like_form.php`
  }),

  searchPoem: (body) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/json_searchPoems.php`
  }),

  getPoemsListAll: (page) => ({
    method: 'get',
    endpoint: `${BASE_URL}/json_PoemsList.php?page=${page}`
  }),
};

