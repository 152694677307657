// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// components

// Styles
import './styles.scss';

// Assets
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';

import Banner from 'components/commonComponents/Banner';
import { Fade } from 'react-reveal';

const Letters = () => {
    const { userDetails, currentLang } = useSelector((store) => store.commonData);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [letters, setLetters] = useState('')
    const [isLoading, setLoading] = useState(false)


    const fetchLetters = async () => {
        const res = await fetchData(restAPIs.getLettersList(currentLang?.id));
        setLetters(res)
        setLoading(false);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchLetters();
    }, [currentLang]);


    const handleOnclick = (id) =>{
        navigate('/poems?letterId='+id)
    }

    return (
        <>
            <Banner title={t('letters')} />
            <div className='container'>
                <div className='__letters_list'>
                    {letters && letters?.map((item, idx) => {
                        return (
                            <Fade bottom cascade duration={2000}>
                                <div className='__single_letter' key={idx} onClick={ () => handleOnclick(item?.letterId)}>
                                    <h1>{item?.letter}</h1>
                                    <h3>({item?.poemsCount})</h3>
                                </div>
                            </Fade>
                        )
                    })}
                </div>
            </div>
        </>
    );
};


export default Letters;
