// Core
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Styles
import './styles.scss';
import { useSelector } from 'react-redux';
import Login from 'components/modals/Login';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useEffect } from 'react';

import Button from 'components/commonComponents/Button';
import { APP_ID } from 'config/constants';
import { ArrowRightOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { Fade } from 'react-reveal';
import Banner from 'components/commonComponents/Banner';

import InputField from 'components/commonComponents/InputField';
// icons

const Subscription = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { userDetails, currentLang } = useSelector((store) => store.commonData);
  const [data, setData] = useState('')
  const [isLoading, setLoading] = useState(false)

  const [referralCode, setreferralCode] = useState('')
  const [discountCode, setdiscountCode] = useState('')
  const [searchParams] = useSearchParams();

  
  const [dataSet, setDataSet] = useState({});
  const [error, setError] = useState('');
  const [isLoadingApply, setIsLoadingApply] = useState(false);
  const { code } = dataSet;
  const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false, severity: '' })

  const handleClick = (id) => {
    userDetails ? referralCode ? navigate('/subscription-payment/' + id + '?refcode=' + referralCode) : discountCode ? navigate('/subscription-payment/' + id + '?discountCode=' + discountCode) : navigate('/subscription-payment/' + id) : setShow(true)
  }

  const handleCloseModel = () => {
    setShow(false);
  };

  const fetchSubscriptions = async () => {
    setreferralCode(searchParams.get('refcode'))
    setdiscountCode(searchParams.get('discountCode'))
    setLoading(true)
    const formData = new FormData();
    formData.append('language', currentLang?.id)
    formData.append('appId', APP_ID)
    if (searchParams.get('refcode'))
      formData.append('refCode', searchParams.get('refcode'))
    if (searchParams.get('discountCode'))
      formData.append('discountCode', searchParams.get('discountCode'))
    const res = await fetchData(restAPIs.getSubscriptions(formData));
    setData(res.info);
    setLoading(false)
  };

  useEffect(() => {
    setreferralCode(searchParams.get('refcode'))
    setdiscountCode(searchParams.get('discountCode'))
    fetchSubscriptions();
  }, [currentLang]);


  const handleSave = async () => {
    const errors = {
      code: !code,
    };

    setError(errors);
    let isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;
    setdiscountCode(code);
    setIsLoadingApply(true);
    setLoading(true);
    const formData = new FormData();
    formData.append('language', currentLang?.id);
    formData.append('appId', APP_ID);
    formData.append('discountCode', code);
    const res = await fetchData(restAPIs.getSubscriptions(formData));
    setData(res.info);
    setError({});
    if (!res.isCodeValid) {
      setErrorAlert({ msg: 'Please check your code', key: Math.random(), open: true, severity: 'error' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
      }, 3000);
    }
    setIsLoadingApply(false);
    setLoading(false);
  }

  const handleOnChange = (key) => (_value) => {
    if (_value) {
      setError({
        ...error,
        [key]: null
      });
    }
    setDataSet({
      ...dataSet,
      [key]: _value
    });
  };



  return (
    <>
      <Banner title={t('subscription')} />
      <div className='container __innerTopPad' style={{ minHeight: '50vh' }}>
        <h1 className='__common-head'>{t('subscription')}</h1>

        <div className='row justify-content-center mb-4'>
          <div className='col-md-6 d-flex align-items-end justify-content-center __disco' style={{gridGap:'10px'}}>
            <InputField
              onChange={handleOnChange('code')}
              value={code}
              placeholder={t('discountCode')}
              type='text'
              label={t('discountCode')}
              error={error.code}
            />
            <Button
              style={{margin:0}}
              withLoader
              loading={isLoadingApply}
              disabled={isLoadingApply}
              handleOnClick={handleSave}
              label={t('submit')}
            />
          </div>
        </div>
        {errorAlert && errorAlert.open ?
                    <p className='error_mg'>{errorAlert.msg}</p> :''}


        {isLoading ?
          <div className="row justify-content-center">
            <div className='text-center'>
              <Spin className='__spin_color' size="large" tip="Loading" />
            </div>
          </div> :
          <div className="row justify-content-center">
            {data && data.map((item, idx) => {
              return (
                <Fade left cascade>
                  <div className="col-md-4 mb-4" key={idx}>
                    <div className="__subBlock" style={{ border: item?.duration !== 30 ? '3px solid #4095FF' : '' }}>
                      {item?.duration !== 30 ? <span className='recommend'>{t('recommended')}</span> : ''}
                      <h1>{item?.duration === 30 ? t('monthly') : t('yearly')} </h1>
                      <h2>AED {item?.price}.00</h2>
                      <p><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} />{item?.subscriptionName}</p>
                      <p><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} />{item?.descline1}</p>
                      <p><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} />{item?.descline2}</p>
                      <p><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} /><b>{item?.duration}</b> {t('days')}</p>
                      <p><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} />AED {item?.price}.00</p>
                      <Button
                        label={item?.duration === 30 ? t('subscribe_monthly') : t('subscribe_yearly')}
                        icon={<ArrowRightOutlined />}
                        handleOnClick={() => handleClick(item?.subscriptionId)}
                        style={{ marginLeft: '0', width: '100%' }}
                        className='mt-4'
                      />
                    </div>
                  </div>
                </Fade>
              )
            })}

          </div>
        }
        <Login
          open={show}
          onClose={handleCloseModel}
        />
      </div>
    </>
  );
};

export default Subscription;