// Core
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Styles
import './styles.scss';



import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';

import { Spin } from 'antd';
import { Fade } from 'react-reveal';
import Banner from 'components/commonComponents/Banner';

const Founder = () => {
    const { userDetails, currentLang } = useSelector((store) => store.commonData);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const fetchResult = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.getFounderInfo(currentLang?.id));
        setData(res.founder);
        setLoading(false)
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchResult();
    }, [currentLang]);

    return (
        <>
            <Banner title={data && data[0]?.name}/>
            <div className='container __innerTopPad' style={{ minHeight: '50vh' }}>
                {
                    isLoading ?
                        <div className='text-center'>
                            <Spin className='__spin_color' size="large" tip="Loading" />
                        </div>
                        :
                        <>
                            <div className='row justify-content-center'>
                                <Fade bottom cascade>
                                    <div className='col-md-3 mb-3'>
                                        <img src={data && data[0]?.logo} alt="" width={'100%'} height={'200px'} style={{ objectFit: 'contain' }} />
                                    </div>
                                </Fade>
                                {data && data?.map((item, idx) => {
                                    return (
                                        <Fade bottom cascade>
                                            <div className='col-md-9 mb-3' key={idx}>
                                                <h1 className='__common_page_head' style={{ color: '#4095FF', margin: 0 }}>{item?.name}</h1>
                                                <p dangerouslySetInnerHTML={{ __html: item?.description }} style={{ lineHeight: 1.7 }}></p>
                                            </div>
                                        </Fade>
                                    )
                                })}

                            </div>
                        </>
                }
            </div>

        </>
    );
};

export default Founder;
