// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// components

// Styles
import './styles.scss';

// Assets
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';

import { Button as ButtonAntD, Modal, Alert, Badge, Rate, Spin, Tabs, message } from 'antd';

import {
    ShareAltOutlined, LikeOutlined, UpOutlined, DownOutlined, CommentOutlined, HeartTwoTone, UserOutlined, InfoOutlined, SoundTwoTone,
    DeleteFilled, EditFilled, HeartFilled, HeartOutlined, LikeFilled, MessageOutlined, PictureOutlined, PlayCircleOutlined, PlusOutlined
} from '@ant-design/icons';

import { Fade } from 'react-reveal';
import EditComment from 'components/modals/EditComment';

import TimeAgo from 'react-timeago'
import TextArea from 'components/commonComponents/TextArea';
import Button from 'components/commonComponents/Button';
import Login from 'components/modals/Login';
import { useRef } from 'react';
import SocialShare from 'components/modals/SocialShare';
import ReactPlayer from 'react-player';
import { APP_ID } from 'config/constants';
import { useSpeechSynthesis, useSpeechRecognition } from 'react-speech-kit';
import PoetModal from 'components/modals/PoetModal';
import InfoModal from 'components/modals/InfoModal';
import Highlight from 'components/commonComponents/Highlight';


const ToggleButton = ({ isExpanded, onClick }) => {
    return (
        <button className="__btn-toggle" onClick={onClick}>
            {isExpanded ? <UpOutlined /> : <DownOutlined />}
        </button>
    );
};

const defaultHeight = 220;


const ViewPoem = () => {
    const { userDetails, currentLang } = useSelector((store) => store.commonData);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { poemId } = useParams();

    const [poem, setPoem] = useState('')
    const [isLoading, setLoading] = useState(false)

    const [messageApi, contextHolder] = message.useMessage();
    const [totalLikes, setTotalLikes] = useState(0);
    const [isLiked, setIsLiked] = useState(false);

    const [comments, setComments] = useState([]);
    const [errorComment, setErrorComment] = useState('')
    const [commentData, setCommentData] = useState({})
    const [isLoadingComment, setIsLoadingComments] = useState(false);
    const [isLoadingMoreComment, setIsLoadingMoreComments] = useState(false);

    const [isLastPage, setIsLastPage] = useState(false);
    const [openDeleteComment, setOpenDeleteComment] = useState(false);
    const { comment = '' } = commentData;
    const [openEditComment, setOpenEditComment] = useState('');
    const commentsRef = useRef(null);
    const [commentPage, setCommentPage] = useState(1)

    const [showLogin, setShowLogin] = useState(false);


    const [isFavourite, setIsFavourite] = useState(false)

    const [openShareSocial, setOpenShareSocial] = useState(false);
    const [summary, setSummary] = useState('');
    const [videoPlay, setVideoPlay] = useState(false)

    const [openInfo, setOpenInfo] = useState(false);

    const [openInfoPoet, setOpenInfoPoet] = useState(false);
    const [poet, setPoet] = useState('')
    const [poetId, setPoetId] = useState('')


    const { speak, cancel, speaking } = useSpeechSynthesis();
    const [valueToSpeak, setValueToSpeak] = useState('الله');


    const [searchParams] = useSearchParams();
    const [searchkey, setSearchkey] = useState(searchParams.get('searchkey'))

    const fetchPoem = async () => {
        const res = await fetchData(restAPIs.getPoem(currentLang?.id, poemId));
        setPoem(res[0])
        let speaker = ''
        for (let i = 0; i < res[0].lines.length; i++) {
            const element = res[0].lines[i];
            speaker += element.line1 + ' ' + element.line2 + ' '
        }

        // setValueToSpeak(speaker)

        setLoading(false);
        fetchPoet(res[0].poet?.poetId);
        setIsFavourite(res[0].isFavourite)
    }

    const fetchPoet = async (id) => {
        const res = await fetchData(restAPIs.getPoetList(currentLang?.id, id));
        setPoet(res.items[0])
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        setSearchkey(searchParams.get('searchkey'))
        fetchPoem();
        fetchComments();
        fetchPoemLike();
    }, [currentLang]);



    const fetchComments = async () => {
        setCommentPage(1)
        setIsLoadingMoreComments(true)
        const res = await fetchData(restAPIs.commentList(poemId, 1));
        if (res?.statusCode === 200) {
            setComments(res.comments);
            setIsLastPage(res.isLastPage)
            setIsLoadingMoreComments(false)
        }
    };


    const fetchMoreComments = async () => {
        let page = commentPage + 1
        setCommentPage(page)
        setIsLoadingMoreComments(true)

        const res = await fetchData(restAPIs.commentList(poemId, page));
        if (res?.statusCode === 200) {
            setComments(comments.concat(res.comments));
            setIsLastPage(res.isLastPage)
            setIsLoadingMoreComments(false)

        }
    };

    const handleOpenLoginModel = () => {
        setShowLogin(true);
    }

    const handleOnChangeComment = (key) => (value) => {
        setErrorComment({
            ...errorComment,
            [key]: null
        });
        setCommentData({
            ...commentData,
            [key]: value
        });
    };

    const handleSubmitComment = async () => {
        const errors = {
            comment: !comment,
        };
        setErrorComment(errors);
        let isFormValid = Object.values(errors).every((item) => !item);
        if (!isFormValid) return;
        setIsLoadingComments(true);
        const formData = new FormData();
        formData.append('action', 'add');
        formData.append('poemId', poemId);
        formData.append('comment', comment);
        const res = await fetchData(restAPIs.comment(formData));
        if (res.statusCode === 200) {
            fetchComments();
            setCommentData({})
            messageApi.open({
                type: 'success',
                content: res.errormessage,
            });
        }
        else {
            messageApi.open({
                type: 'error',
                content: res.errormessage,
            });
        }
        setIsLoadingComments(false);
    };

    const handleCloseLoginModel = () => {
        setShowLogin(false);
    };


    const handleDeleteCommentClick = () => {
        setOpenDeleteComment(true);
    };

    const handleEditCommentClick = (value) => {
        setOpenEditComment(value);
    };

    const handleCloseEditComment = (value) => {
        setOpenEditComment('');
        if (value)
            fetchComments();
    }

    const handleCloseDeleteComment = async (action, commentId) => {
        setOpenDeleteComment(false);
        if (action) {
            const formData = new FormData();
            formData.append('action', 'delete');
            formData.append('poemId', poemId);
            formData.append('commentId', commentId);
            const res = await fetchData(restAPIs.comment(formData));
            if (res.statusCode === 200) {
                fetchComments();
                messageApi.open({
                    type: 'success',
                    content: res.errormessage,
                });
            }
            else {
                messageApi.open({
                    type: 'error',
                    content: res.errormessage,
                });
            }
        }
    };

    const gotoComments = () => {
        commentsRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const handleFavorite = async () => {
        if (userDetails) {
            let isFav = !isFavourite
            const formData = new FormData();
            formData.append('action', isFavourite ? 'delete' : 'add');
            formData.append('poemId', poemId);
            formData.append('appId', APP_ID);
            const res = await fetchData(restAPIs.addFavourite(formData));
            if (res.statusCode === 200) {
                setIsFavourite(isFav)
                messageApi.open({
                    type: 'success',
                    content: 'Poem added to favourites',
                });
            }
            else {
                messageApi.open({
                    type: 'error',
                    content: res.errormessage,
                });
            }

        }
        else { setShowLogin(true) }
    }

    const handleOpenShareSocial = () => {
        setOpenShareSocial(true);
    };
    const handleCloseShareSocial = () => {
        setOpenShareSocial(false);
    };

    const fetchPoemLike = async () => {
        const formData = new FormData();
        formData.append('poemId', poemId)
        const res = await fetchData(restAPIs.likePoem(formData));
        setIsLiked(res.isLiked)
        setTotalLikes(res.totalLikes)
    }

    const likePoem = async () => {
        if (userDetails) {
            const formData = new FormData();
            formData.append('action', isLiked ? 'delete' : 'add');
            formData.append('poemId', poemId);
            const res = await fetchData(restAPIs.likePoem(formData));
            if (res.statusCode === 200) {
                setIsLiked(!isLiked)
                setTotalLikes(+res.totalLikes)
                messageApi.open({
                    type: 'success',
                    content: res.errormessage,
                });
            }
            else {
                messageApi.open({
                    type: 'error',
                    content: res.errormessage,
                });
            }
        }
        else {
            setShowLogin(true)
        }
    }


    const [heightCurrent, setHeightCurrent] = useState(defaultHeight);
    const [heightMax, setHeightMax] = useState(defaultHeight);
    const [heightMin, setHeightMin] = useState(defaultHeight);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isOverflow, setIsOverflow] = useState(false);

    useEffect(() => {
        const element = document.querySelector(".text-display")
        const heightClient = element?.clientHeight || defaultHeight;
        const scrollClient = element?.scrollHeight || defaultHeight;
        if (heightClient !== scrollClient) {
            setIsOverflow(true);
            setHeightMax(scrollClient);
            setHeightMin(heightClient);
            setHeightCurrent(heightClient);
        }
    }, [poem]);

    const handleClickBtn = () => {
        setHeightCurrent(isExpanded ? heightMin : heightMax);
        setIsExpanded((prev) => !prev);
    };

    const handleCloseInfo = () => {
        setOpenInfo(false)
    }

    const handleOpenInfo = () => {
        setOpenInfo(true)
    }


    const handleCloseInfoPoet = () => {
        setOpenInfoPoet(false)
    }

    const handleOpenInfoPoet = () => {
        setOpenInfoPoet(true)
    }

    const handleSpeak = () => {
        speak({
            text: valueToSpeak && valueToSpeak, rate: 1.2,
            lang: 'ar-SA',
            pitch: 2,
            rate: 2,
            volume: 1,
        })
    }

    const highlightText = (text, highlight) => {
        const parts = text?.split(new RegExp(`(${highlight})`, 'gi'));
        let highlightedHTML = '';
        parts?.forEach((part, i) => {
            if (part.toLowerCase() === highlight.toLowerCase()) {
                highlightedHTML += `<span key=${i} style="font-weight: bold; background: #ffff0054;">${part}</span>`;
            } else {
                highlightedHTML += part;
            }
        });
        return highlightedHTML;
    };



    return (
        <>
            <img src={poem?.poemCover} alt="" className='__top_banner_img' />
            <div className='__top_gradient'></div>
            <Fade bottom cascase >
                <div className='container'>
                    <div className='__padding_top '>
                        <h1 className='__banner_head'><Highlight text={poem?.poemTitle ? poem?.poemTitle : ''} highlight={searchkey} /></h1>
                        <p className='__common_para' style={{ color: '#fff' }}>{t('poet')} <b>{poem?.poet?.fullName}</b></p>
                        <h4 className='__common_para mb-5' style={{ color: '#fff' }}>{t('verses')} <b>{poem?.linesCount}</b></h4>
                        <div className='__box root'>
                            <div className='__icon_set'>

                                <ButtonAntD shape="circle" icon={<ShareAltOutlined />} onClick={handleOpenShareSocial} />
                                <ButtonAntD icon={isFavourite ? <HeartFilled /> : <HeartOutlined />} style={{ color: isFavourite ? 'red' : 'initial' }} shape="circle" onClick={() => handleFavorite()} />
                                <ButtonAntD shape="circle" icon={<UserOutlined />} onClick={handleOpenInfoPoet} />
                                <ButtonAntD shape="circle" icon={<InfoOutlined />} onClick={handleOpenInfo} />
                            </div>

                            <SocialShare
                                open={openShareSocial}
                                onClose={handleCloseShareSocial}
                                title={poem?.poemTitle}
                                description={poem?.description}
                                image={poem?.poemCover}
                                links={poem?.links}
                                url={window.location.href}
                                summary={summary}
                            />


                            <div className={"expanded"} >
                                {poem && poem?.lines.map((item, idx) => {
                                    return (
                                        searchkey ? <div className='mb-3 text-center' key={idx}>
                                            <p key={idx + 'i' + 2} id={idx + 'i' + 2} className='__common_para' dangerouslySetInnerHTML={{ __html: highlightText(item?.line1, searchkey) }}></p>
                                            <p key={idx + 'b' + 2} id={idx + 'b' + 2} className='__common_para' dangerouslySetInnerHTML={{ __html: highlightText(item?.line2, searchkey) }}></p>
                                        </div>
                                            :
                                            <div className='mb-3 text-center' key={idx}>
                                                <p key={idx + 'i' + 2} id={idx + 'i' + 2} className='__common_para' dangerouslySetInnerHTML={{ __html: item?.line1 }}></p>
                                                <p key={idx + 'b' + 2} id={idx + 'b' + 2} className='__common_para' dangerouslySetInnerHTML={{ __html: item?.line2 }}></p>
                                            </div>

                                    )
                                })}
                            </div>
                            {/* <div className='__buton_showmore'>
                                {isOverflow && <ToggleButton isExpanded={isExpanded} onClick={handleClickBtn} />}
                            </div> */}

                            <div className='__comment_sec'>
                                <div className='__comnt_icon_set'>

                                    <a style={{ color: isLiked ? '#ff0000' : 'inherit' }} onClick={() => likePoem()}>{isLiked ? <LikeFilled /> : <LikeOutlined />} <p>{totalLikes && totalLikes} {t('likes')}</p></a>
                                    <a onClick={() => gotoComments()}><CommentOutlined /> <p>{comments && comments.length} {t('comments')}</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
            <Fade bottom cascase>
                < div className='container __right_align'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='__comment_sec d-flex flex-column' >
                                <h3 className='__common-head' ref={commentsRef}>{t('comments')} ({comments && comments.length})</h3>
                                {comments && comments.length === 0 ? 'No Comments' : ''}
                                {
                                    comments && comments?.map((item, idx) => {
                                        return (
                                            <div className='__com-ed-wrap' key={idx + 'i'}>
                                                <div className='__comment_wrap'>
                                                    <img src={item.profilePic} alt={item?.fullName} />
                                                    <div style={{ margin: '0 15px' }}>
                                                        <h1>{item.fullName} <span> <TimeAgo date={item?.commentDate} /></span></h1>
                                                        <p>{item.comment}</p>
                                                    </div>
                                                </div>
                                                {item.isOwner ? <div className='__com_actions'>
                                                    <button onClick={() => handleEditCommentClick(idx)}><EditFilled /></button>

                                                    <EditComment
                                                        key={idx}
                                                        open={openEditComment === idx}
                                                        onClose={handleCloseEditComment}
                                                        commentData={item.comment}
                                                        poemId={poemId}
                                                        commentId={item.commentId}
                                                    />
                                                    <button onClick={() => handleDeleteCommentClick()}><DeleteFilled /></button>

                                                    <Modal
                                                        open={openDeleteComment}
                                                        onClose={handleCloseDeleteComment}
                                                        className='__common_model_class'
                                                        footer={null}
                                                        closable={false}
                                                    >
                                                        <h3 className='____common_model_class_head' style={{ marginBottom: '10px' }}>
                                                            {t('delete')}
                                                        </h3>

                                                        <div className='.____common_model_class_desc'>
                                                            <p style={{ whiteSpace: 'pre-line', marginBottom: 0 }}>Are you sure you want to remove this comment !</p>
                                                        </div>
                                                        <div className='d-flex align-items-center justify-content-end' style={{ marginTop: ' 15px', gridGap: '10px' }}>
                                                            <a onClick={() => handleCloseDeleteComment(false)} style={{ cursor: 'pointer' }}>{t('cancel')}</a>
                                                            <Button
                                                                label={t('ok')}
                                                                handleOnClick={() => handleCloseDeleteComment(true, item.commentId)}
                                                                style={{ margin: '0' }}
                                                            />
                                                        </div>
                                                    </Modal>

                                                </div> : ''}
                                            </div>
                                        )
                                    })
                                }
                                {isLoadingMoreComment ?
                                    <Spin className='__spin_color' style={{ margin: '10px 0' }} size="large" tip="Loading" /> : ''}
                                {!isLastPage && <p><ButtonAntD onClick={() => fetchMoreComments()}>{t('more')}</ButtonAntD></p>}

                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='__add_comment_wrap'>
                                            <span className='mb-2'>{t('read_smtg_u_like')}</span>
                                            {
                                                userDetails ?

                                                    <div className='__comment_adding'>
                                                        <img src={userDetails?.profile_pic} />
                                                        <div>
                                                            <TextArea
                                                                type="text"
                                                                placeholder={t('your_comment')}
                                                                height={110}
                                                                value={comment}
                                                                onChange={handleOnChangeComment('comment')}
                                                                error={errorComment.comment}
                                                            />
                                                            <Button
                                                                label={t('submit')}
                                                                className='mt-4'
                                                                handleOnClick={handleSubmitComment}
                                                                withLoader
                                                                loading={isLoadingComment}
                                                                disabled={isLoadingComment}
                                                                style={{ margin: '0' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                        <Button
                                                            label={t('write_a_comment')}
                                                            className='mt-4'
                                                            handleOnClick={handleOpenLoginModel}
                                                            style={{ margin: '0' }}
                                                        />
                                                    </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
            <Login
                open={showLogin}
                onClose={handleCloseLoginModel}
            />
            <PoetModal open={openInfoPoet} onClose={handleCloseInfoPoet} poet={poet} />
            <InfoModal open={openInfo} onClose={handleCloseInfo} title={poem?.poemTitle} description={poem?.description} />
        </>
    );
};


export default ViewPoem;
