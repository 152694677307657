// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// components

// Styles
import './styles.scss';

// Assets
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';

import Poet from 'components/commonComponents/Poet';
import Banner from 'components/commonComponents/Banner';
import { Fade } from 'react-reveal';
import { Spin } from 'antd';

const Poets = () => {
    const { userDetails, currentLang } = useSelector((store) => store.commonData);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [poets, setPoets] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [isLoadingPage, setLoadingPage] = useState(false);
    const [isLastPage, setIsLastPage] = useState(false);
    const [page, setPage] = useState(1);


    const fetchPoets = async () => {
        setLoading(true);
        const res = await fetchData(restAPIs.getPoetList(currentLang?.id, null, 1));
        setPoets(res.items)
        setLoading(false);
        setIsLastPage(res.isLastPage);
    }


    const handleScroll = () => {
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (windowHeight + scrollTop >= documentHeight - 200 && !isLoadingPage && !isLastPage) {
            fetchPaginatePoets(page + 1);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isLoadingPage, isLastPage, page]);

    const fetchPaginatePoets = async (pageToFetch) => {
        setLoadingPage(true);
        const res = await fetchData(restAPIs.getPoetList(currentLang?.id, null, pageToFetch));
        setLoadingPage(false);

        if (res?.statusCode === 200) {
            setPoets(prevList => [...prevList, ...res.items]);
            setIsLastPage(res.isLastPage);
            setPage(pageToFetch);
        }
    };



    const handleOnclick = (id) => {
        navigate('/poet/' + id);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchPoets();
    }, [currentLang]);


    return (
        <>
            <Banner title={t('poets')} />
            <div className='container'>
                {isLoading ?
                    <div className='text-center mt-4'>
                        <Spin className='__spin_color' size="large" tip="Loading" />
                    </div>
                    :
                    <>
                        <div className='__poet_list'>
                            {poets && poets?.map((item, idx) => {
                                return (
                                    <Fade bottom duration={2000} key={idx}>
                                        <Poet item={item} key={idx} handleOnClick={() => handleOnclick(item.poetId)} />
                                    </Fade>
                                )
                            })}
                        </div>
                        {isLoadingPage ?
                                        <>
                                <div className='text-center mt-4'>
                                    <Spin className='__spin_color' size="large" tip="Loading" />
                                </div>
                                        </>
                                        : ''

                                    }
                    </>
                }
            </div>
        </>
    );
};


export default Poets;
