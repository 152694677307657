// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// components

// Styles
import './styles.scss';

// Assets
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';

import Poet from 'components/commonComponents/Poet';
import Banner from 'components/commonComponents/Banner';
import { Fade } from 'react-reveal';
import Poem from 'components/commonComponents/Poem';
import Highlight from 'components/commonComponents/Highlight';

const ViewPoet = () => {
    const { userDetails, currentLang } = useSelector((store) => store.commonData);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [poet, setPoet] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [poems, setPoems] = useState('');
    const { poetId } = useParams();
    const [searchParams] = useSearchParams();
    const [searchkey, setSearchkey] = useState(searchParams.get('searchkey'))

    const fetchPoet = async () => {
        const res = await fetchData(restAPIs.getPoetList(currentLang?.id, poetId));
        setPoet(res.items[0]);
        setLoading(false);
    }

    const fetchPoems = async () => {
        const res = await fetchData(restAPIs.getPoemsList(currentLang?.id, poetId));
        setPoems(res.items);
        setLoading(false);
    }
    const handleOnclick = (id) => {
        navigate('/poem/' + id);
    }
    useEffect(() => {
        setSearchkey(searchParams.get('searchkey'))
        window.scrollTo(0, 0);
        fetchPoet();
        fetchPoems();
    }, [currentLang]);


    const highlightText = (text, highlight) => {
        const parts = text?.split(new RegExp(`(${highlight})`, 'gi'));
        let highlightedHTML = '';
        parts?.forEach((part, i) => {
            if (part.toLowerCase() === highlight.toLowerCase()) {
                highlightedHTML += `<span key=${i} style="font-weight: bold; background: #ffff0054;">${part}</span>`;
            } else {
                highlightedHTML += part;
            }
        });
        return highlightedHTML;
    };


    return (
        <>
            <Banner title='' />
            <Fade bottom duration={2000}>

                <div className='container'>
                    <div className='__poet-wrapper'>
                        <div className='row'>
                            <div className='col-md-3 __img_sec'>
                                <img src={poet?.poetPicture} alt={poet?.fullName} />
                            </div>
                            <div className='col-md-9 __content_sec'>
                                <h2> <Highlight text={poet?.fullName ? poet?.fullName : ''} highlight={searchkey} /></h2>
                                {searchkey ? <p className='__common_para text-justify' style={{ fontSize: '20px', marginBottom: '20px' }} dangerouslySetInnerHTML={{ __html: highlightText(poet?.biography && poet?.biography, searchkey) }}></p>
                                 : <p className='__common_para text-justify' style={{ fontSize: '20px', marginBottom: '20px' }} dangerouslySetInnerHTML={{ __html: poet?.biography }}></p> }
                                <p><b>{t('country')} : </b>{poet?.country}</p>
                                <p><b>{t('city')} : </b>{poet?.city}</p>
                                <p><b>{t('year_of_birth')} : </b>{poet?.birthYear}</p>
                                <p><b>{t('year_of_death')} : </b>{poet?.deathYear}</p>
                                <p style={{ marginTop: '15px' }}><b>{t('info_links')}</b></p>
                                {poet.infolinks && <p><ul>
                                    {poet && poet.infolinks.split(',')?.map((item, idx) => {
                                        return (
                                            <li><a href={item} target='_blank'> {item}</a></li>
                                        )
                                    })}
                                </ul></p>}
                            </div>
                            {(poems.length > 0) ? <>
                                <h3>{t('poems')}</h3>
                                <div className='__poems_list'>
                                    {poems && poems.map((item, idx) => {
                                        return (
                                            <Fade bottom duration={2000}>
                                                <Poem item={item} key={idx} handleOnClick={() => handleOnclick(item.poemId)} />
                                            </Fade>
                                        )
                                    })
                                    }
                                    <div></div>
                                    <div></div>
                                </div></> : ''}
                        </div>
                    </div>
                </div>
            </Fade>
        </>
    );
};


export default ViewPoet;
