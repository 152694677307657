// Core
import React from 'react';
import PropTypes from 'prop-types';
// Style
import './styles.scss';

import { MenuOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Highlight from '../Highlight';

const Poem = (props) => {
    const { t } = useTranslation();
    const { item,searchkey, handleOnClick, ...rest } = props;

    return (
        <div className='___single' onClick={handleOnClick}>
            <div className="___img">
                <img src={item?.poemCover} alt={item?.poemTitle} />
            </div>
            {item?.inappFree > 0 && <div className='__premium'>{t('premium')}</div>}
            <div className='___content'>
                <h1><Highlight text={item?.poemTitle ? item?.poemTitle : ''} highlight={searchkey} /></h1>
                <p>{t('poet')}  : <b>{item?.poet?.fullName}</b></p>
                <p><button>{t('read_now')}</button></p>
                <div className='__comment_sec'>
                    <p className='__comnt_icon_set'>
                        <a><MenuOutlined /> <p><b>{item?.linesCount}</b> {t('verses')}</p></a>
                        <a><svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="TranslateIcon"><path d="m12.87 15.07-2.54-2.51.03-.03c1.74-1.94 2.98-4.17 3.71-6.53H17V4h-7V2H8v2H1v1.99h11.17C11.5 7.92 10.44 9.75 9 11.35 8.07 10.32 7.3 9.19 6.69 8h-2c.73 1.63 1.73 3.17 2.98 4.56l-5.09 5.02L4 19l5-5 3.11 3.11.76-2.04zM18.5 10h-2L12 22h2l1.12-3h4.75L21 22h2l-4.5-12zm-2.62 7 1.62-4.33L19.12 17h-3.24z"></path></svg> {t('letter')} : <p><b>{item?.letter}</b></p></a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Poem;

Poem.propTypes = {
    item: PropTypes.any,
    handleOnClick: PropTypes.func,
};
