// Core
import React, { useEffect, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// components

// Styles
import './styles.scss';

// Assets
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';

import { Button, Spin } from 'antd';
import Poem from 'components/commonComponents/Poem';
import Banner from 'components/commonComponents/Banner';
import { Fade } from 'react-reveal';
import Login from 'components/modals/Login';

const Poems = () => {
    const { userDetails, currentLang } = useSelector((store) => store.commonData);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [queryParams, setQueryParams] = useSearchParams();
    const [poetId, setPoetId] = useState(queryParams.get('poetId'))
    const [letterId, setLetterId] = useState(queryParams.get('letterId'))
    const loaction = useLocation();
    const [poems, setPoems] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [isLoadingPage, setLoadingPage] = useState(false);
    const [isLastPage, setIsLastPage] = useState(false);
    const [page, setPage] = useState(1);
    const [show, setShow] = useState(false);


    const fetchPoems = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.getPoemsList(currentLang?.id, poetId, letterId, 1));
        setPoems(res.items)
        setIsLastPage(res.isLastPage);
        setLoading(false);
    }



    const handleScroll = () => {
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (windowHeight + scrollTop >= documentHeight - 200 && !isLoadingPage && !isLastPage) {
            fetchPaginatePoems(page + 1);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isLoadingPage, isLastPage, page]);

    const fetchPaginatePoems = async (pageToFetch) => {
        setLoadingPage(true);
        const res = await fetchData(restAPIs.getPoemsList(currentLang?.id, poetId, letterId, pageToFetch));
        setLoadingPage(false);

        if (res?.statusCode === 200) {
            setPoems(prevList => [...prevList, ...res.items]);
            setIsLastPage(res.isLastPage);
            setPage(pageToFetch);
        }
    };



    useEffect(() => {
        window.scrollTo(0, 0);
        fetchPoems();
    }, [currentLang, loaction]);

    const handleOnclick = (item) => {
        item.inappFree === 0 ? navigate('/poem/' + item.poemId) : userDetails ? userDetails.isPremium ? navigate('/poem/' + item.poemId) : navigate('/subscribe') : setShow(true)
    }

    const handleCloseModel = (value) => {
        setShow(false);
    };

    return (
        <>
            <Banner title={t('poems')} />
            <div className='container'>
                {isLoading ?
                    <div className='text-center mt-4'>
                        <Spin className='__spin_color' size="large" tip="Loading" />
                    </div>
                    :
                    <>
                        <div className='__poems_list'>
                            {poems && poems.map((item, idx) => {
                                return (
                                    <Fade bottom duration={2000}>
                                        <Poem item={item} key={idx} handleOnClick={() => handleOnclick(item)} />
                                    </Fade>
                                )
                            })
                            }
                        </div>
                        {isLoadingPage ?
                            <>
                                <div className='text-center mt-4'>
                                    <Spin className='__spin_color' size="large" tip="Loading" />
                                </div>
                            </>
                            : ''

                        }
                    </>
                }
            </div>
            <Login
                open={show}
                onClose={handleCloseModel}
            />
        </>
    );
};


export default Poems;
