// Core
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// components

// Styles
import './styles.scss';

// Assets
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';

import BackgroundImg from './../../../assets/images/background.png'
import { Button, message } from 'antd';
import { ShareAltOutlined, LikeOutlined, LikeFilled, HeartFilled, HeartOutlined, CaretRightOutlined, CaretLeftOutlined, UpOutlined, DownOutlined, CommentOutlined, HeartTwoTone, UserOutlined, InfoOutlined, SoundTwoTone } from '@ant-design/icons';
import Slider from "react-slick";
import Poem from 'components/commonComponents/Poem';
import Poet from 'components/commonComponents/Poet';
import { Fade } from 'react-reveal';
import Login from 'components/modals/Login';
import SocialShare from 'components/modals/SocialShare';
import PoetModal from 'components/modals/PoetModal';
import InfoModal from 'components/modals/InfoModal';
import { APP_ID } from 'config/constants';

const ToggleButton = ({ isExpanded, onClick }) => {
    return (
        <button className="__btn-toggle" onClick={onClick}>
            {isExpanded ? <UpOutlined /> : <DownOutlined />}
        </button>
    );
};
const defaultHeight = 220
const HomePage = () => {
    const { userDetails, currentLang } = useSelector((store) => store.commonData);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [poets, setPoets] = useState('')
    const [poem, setPoem] = useState('')
    const [poems, setPoems] = useState('')
    const [isLoading, setLoading] = useState(false)

    const [totalLikes, setTotalLikes] = useState(0);
    const [isLiked, setIsLiked] = useState(false);
    const [comments, setComments] = useState([]);
    const [showLogin, setShowLogin] = useState(false);

    const poemSlider = useRef();
    const poetSlider = useRef();

    const [openInfo, setOpenInfo] = useState(false);
    const [openShareSocial, setOpenShareSocial] = useState(false);
    const [openInfoPoet, setOpenInfoPoet] = useState(false);
    const [poet, setPoet] = useState('')
    const [isFavourite, setIsFavourite] = useState(false)
    const [summary, setSummary] = useState('');
    const [poemId, setPoemId] = useState('')


    const settings = {
        className: "center",
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 2,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const settingsPoets = {
        className: "center",
        infinite: true,
        slidesToShow: 4,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    initialSlide: 4
                }
            },
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };

    const fetchComments = async (id) => {
        const res = await fetchData(restAPIs.commentList(id, 1));
        if (res?.statusCode === 200) {
            setComments(res.comments);
        }
    };


    const fetchPoemLike = async (id) => {
        const formData = new FormData();
        formData.append('poemId', id)
        const res = await fetchData(restAPIs.likePoem(formData));
        setIsLiked(res.isLiked)
        setTotalLikes(res.totalLikes)
    }

    const likePoem = async () => {
        if (userDetails) {
            const formData = new FormData();
            formData.append('action', isLiked ? 'delete' : 'add');
            formData.append('poemId', poem.poemId);
            const res = await fetchData(restAPIs.likePoem(formData));
            if (res.statusCode === 200) {
                setIsLiked(!isLiked)
                setTotalLikes(+res.totalLikes)
                messageApi.open({
                    type: 'success',
                    content: res.errormessage,
                });
            }
            else {
                messageApi.open({
                    type: 'error',
                    content: res.errormessage,
                });
            }
        }
        else {
            setShowLogin(true)
        }
    }


    const handleCloseLoginModel = () => {
        setShowLogin(false);
    };


    const fetchPoets = async () => {
        const res = await fetchData(restAPIs.getPoetList(currentLang?.id));
        setPoets(res.items)
        setLoading(false);
    }

    const fetchPoem = async () => {
        const res = await fetchData(restAPIs.getPoem(currentLang?.id));
        setPoem(res[0])
        setLoading(false);
        fetchComments(res[0].poemId);
        fetchPoemLike(res[0].poemId);
        setPoemId(res[0].poemId)
        fetchPoet(res[0].poet?.poetId);
        setIsFavourite(res[0].isFavourite)
    }


    const fetchPoet = async (id) => {
        const res = await fetchData(restAPIs.getPoetList(currentLang?.id, id));
        setPoet(res.items[0])
    }

    const fetchPoems = async () => {
        const res = await fetchData(restAPIs.getPoemsList(currentLang?.id));
        setPoems(res.items)
        setLoading(false);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchPoets();
        fetchPoem();
        fetchPoems();
    }, [currentLang]);

    const handleSeeAll = (goto) => {
        navigate('/' + goto);
    }

    const handleOnclick = (goto, id) => {
        navigate('/' + goto + '/' + id)
    }

    const gotoNext = (type) => {
        if (type === 'poem')
            poemSlider.current.slickNext()
        else
            poetSlider.current.slickNext()
    }

    const gotoPrev = (type) => {
        if (type === 'poem')
            poemSlider.current.slickPrev()
        else
            poetSlider.current.slickPrev()
    }

    const handleOpenShareSocial = () => {
        setOpenShareSocial(true);
    };
    const handleCloseShareSocial = () => {
        setOpenShareSocial(false);
    };

    const handleCloseInfo = () => {
        setOpenInfo(false)
    }

    const handleOpenInfo = () => {
        setOpenInfo(true)
    }

    const handleCloseInfoPoet = () => {
        setOpenInfoPoet(false)
    }

    const handleOpenInfoPoet = () => {
        setOpenInfoPoet(true)
    }


    const [heightCurrent, setHeightCurrent] = useState(defaultHeight);
    const [heightMax, setHeightMax] = useState(defaultHeight);
    const [heightMin, setHeightMin] = useState(defaultHeight);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isOverflow, setIsOverflow] = useState(false);

    useEffect(() => {
        const element = document.querySelector(".text-display")
        const heightClient = element?.clientHeight || defaultHeight;
        const scrollClient = element?.scrollHeight || defaultHeight;
        if (heightClient !== scrollClient) {
            setIsOverflow(true);
            setHeightMax(scrollClient);
            setHeightMin(heightClient);
            setHeightCurrent(heightClient);
        }
    }, [poem]);

    const handleClickBtn = () => {
        setHeightCurrent(isExpanded ? heightMin : heightMax);
        setIsExpanded((prev) => !prev);
    };

    const handleFavorite = async () => {
        if (userDetails) {
            let isFav = !isFavourite
            const formData = new FormData();
            formData.append('action', isFavourite ? 'delete' : 'add');
            formData.append('poemId', poemId);
            formData.append('appId', APP_ID);
            const res = await fetchData(restAPIs.addFavourite(formData));
            if (res.statusCode === 200) {
                setIsFavourite(isFav)
                messageApi.open({
                    type: 'success',
                    content: 'Poem added to favourites',
                });
            }
            else {
                messageApi.open({
                    type: 'error',
                    content: res.errormessage,
                });
            }

        }
        else { setShowLogin(true) }
    }



    return (
        <>
            <img src={poem?.poemCover} alt="" className='__top_banner_img' />
            <div className='__top_gradient'></div>
            <Fade left cascase >
                <div className='container'>
                    <div className='__padding_top '>
                        <h1 className='__banner_head'>{poem?.poemTitle}</h1>
                        <p className='__common_para' style={{ color: '#fff' }}>{t('poet')} <b>{poem?.poet?.fullName}</b></p>
                        <h4 className='__common_para mb-5' style={{ color: '#fff' }}>{t('verses')} <b>{poem?.linesCount}</b></h4>
                        <div className='__box root'>
                            <div className='__icon_set'>
                                <Button shape="circle" icon={<ShareAltOutlined />} onClick={handleOpenShareSocial} />
                                <Button icon={isFavourite ? <HeartFilled /> : <HeartOutlined />} style={{ color: isFavourite ? 'red' : 'initial' }} shape="circle"  onClick={() => handleFavorite()} />
                                <Button shape="circle" icon={<UserOutlined />} onClick={handleOpenInfoPoet} />
                                <Button shape="circle" icon={<InfoOutlined />} onClick={handleOpenInfo} />
                            </div>

                            <SocialShare
                                open={openShareSocial}
                                onClose={handleCloseShareSocial}
                                title={poem?.poemTitle}
                                description={poem?.description}
                                image={poem?.poemCover}
                                links={poem?.links}
                                url={window.location.href}
                                summary={summary}
                            />
                            <div
                                className={`${isExpanded ? "expanded" : "collapsed"} text-display`}
                                style={{ height: `${heightCurrent}px` }}
                            >
                                {poem && poem?.lines.map((item, idx) => {
                                    return (
                                        <div className='mb-3 text-center' key={idx}>
                                            <p className='__common_para' dangerouslySetInnerHTML={{ __html: item?.line1 }}></p>
                                            <p className='__common_para' dangerouslySetInnerHTML={{ __html: item?.line2 }}></p>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='__buton_showmore'>
                                {isOverflow && <ToggleButton isExpanded={isExpanded} onClick={handleClickBtn} />}
                            </div>

                            <div className='__comment_sec'>
                                <div className='__comnt_icon_set'>
                                    <a style={{ color: isLiked ? '#ff0000' : 'inherit' }} onClick={() => likePoem()}>{isLiked ? <LikeFilled /> : <LikeOutlined />} <p>{totalLikes && totalLikes} {t('likes')}</p></a>
                                    <a onClick={() => handleOnclick('poem', poem.poemId)}><CommentOutlined /> <p>{comments && comments.length} {t('comments')}</p></a>

                                </div>
                            </div>
                        </div>
                        <div style={{ padding: '20px' }}>
                            <div className='__header_box_sec'>
                                <h2>{t('poems')}</h2>
                                <div className='d-flex' style={{ gridGap: '10px' }}>
                                    <button onClick={() => gotoPrev('poem')}><CaretLeftOutlined /></button>
                                    <button onClick={() => gotoNext('poem')}><CaretRightOutlined /></button>
                                    <button onClick={() => handleSeeAll('poems')}>{t('see_all')}</button>
                                </div>

                            </div>
                            <Slider {...settings} className='__slid' ref={poemSlider}>
                                {poems && poems.map((item, idx) => {
                                    return (
                                        <div style={{ padding: '20px', width: '100%' }} key={idx}>
                                            <Poem item={item} handleOnClick={() => handleOnclick('poem', item.poemId)} />
                                        </div>
                                    )
                                })
                                }
                            </Slider>
                        </div>
                        <div style={{ padding: '20px' }}>
                            <div className='__header_box_sec'>
                                <h2>{t('poets')}</h2>
                                <div className='d-flex' style={{ gridGap: '10px' }}>
                                    <button onClick={() => gotoPrev('poet')}><CaretLeftOutlined /></button>
                                    <button onClick={() => gotoNext('poet')}><CaretRightOutlined /></button>
                                    <button onClick={() => handleSeeAll('poets')}>{t('see_all')}</button>
                                </div>
                            </div>
                            <Slider {...settingsPoets} className='__slid' ref={poetSlider}>
                                {poets && poets?.map((item, idx) => {
                                    return (
                                        <div style={{ padding: '20px', width: '100%' }} key={idx}>
                                            <Poet item={item} handleOnClick={() => handleOnclick('poet', item.poetId)} />
                                        </div>
                                    )
                                })}

                            </Slider>
                        </div>
                    </div>
                </div>
            </Fade>
            <Login
                open={showLogin}
                onClose={handleCloseLoginModel}
            />
            <PoetModal open={openInfoPoet} onClose={handleCloseInfoPoet} poet={poet} />
            <InfoModal open={openInfo} onClose={handleCloseInfo} title={poem?.poemTitle} description={poem?.description} />
        </>
    );
};


export default HomePage;
