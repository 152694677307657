// Core
import React, { useState } from 'react';

// Styles
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useEffect } from 'react';
import { APP_ID } from 'config/constants';
// icons

const Sitemap = () => {
  const [resultr, setResult] = useState('')

  useEffect(() => {
    fetchPoems()
  }, []);


  const fetchPoems = async () => {
    let result = ''
    for (let k = 1; k < 183; k++) {
      const res = await fetchData(restAPIs.getPoemsListAll(k));
      for (let index = 0; index < res.length; index++) {
        let abc = "<url><loc>https://www.hawaya.ae/poem/" + res[index]?.poemId + "</loc><changefreq>weekly</changefreq></url>"
        result = result + abc
      }

    }
    setResult(result)
  }

  const fetchPoets = async () => {
    let result = ''
    const res = await fetchData(restAPIs.getPoetList(1));
    for (let index = 0; index < res.length; index++) {
      let abc = "<url><loc>https://www.hawaya.ae/poet/" + res[index].poetId + "</loc><changefreq>weekly</changefreq></url>"
      result = result + abc
    }
    setResult(result)
  }


  return (
    <>

      <div className='container __innerTopPad' style={{ paddingTop: '20vh' }}>
        
          {resultr}
      </div>

    </>
  );
};

export default Sitemap;