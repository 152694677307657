// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/commonComponents/Button';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { Modal } from 'antd';

const PoetModal = (props) => {
  const { onClose, open, poet } = props;
  const { t } = useTranslation();

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class __poeeet'
        footer={null}
        closable={false}

      >
        <div className='__common_model_content'>
          <div className='row'>
            <div className='col-md-3'>
              <img src={poet?.poetPicture} alt={poet?.fullName} style={{ width: '100%' }} />
            </div>
            <div className='col-md-9'>
              <h3 className='____common_model_class_head' style={{ marginBottom: '10px' }}> {poet?.fullName}</h3>
              <div className='.____common_model_class_desc'>
                <p className='__common_para' style={{ marginBottom: 10, textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: poet?.biography }}></p>
                <p><b>{t('country')} : </b>{poet?.country}</p>
                                <p><b>{t('city')} : </b>{poet?.city}</p>
                                <p><b>{t('year_of_birth')} : </b>{poet?.birthYear}</p>
                                <p><b>{t('year_of_death')} : </b>{poet?.deathYear}</p>
                                <p style={{ marginTop: '15px' }}><b>{t('info_links')}</b></p>
                {poet.infolinks && <p><ul>
                  {poet && poet.infolinks.split(',')?.map((item, idx) => {
                    return (
                      <li><a href={item} target='_blank'> {item}</a></li>
                    )
                  })}
                </ul></p>}
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center justify-content-end' style={{ marginTop: ' 15px', gridGap: '10px' }}>
            <Button
              label={t('cancel')}
              handleOnClick={() => handleClose(false)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PoetModal;

PoetModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  poet: PropTypes.any,
};
