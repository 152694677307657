// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// components
import TimeAgo from 'react-timeago'
// Styles
import './styles.scss';

// Assets
import { Tabs, message } from 'antd';

import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import ChangePassword from 'components/modals/ChangePassword';
import ChangeName from 'components/modals/ChangeName';
import ChangeEmail from 'components/modals/ChangeEmail';
import AddInvitation from 'components/modals/AddInvitation';
import DeleteAccount from 'components/modals/DeleteAccount';
import Unsubscribe from 'components/modals/Unsubscribe';
import { useRef } from 'react';
import { APP_ID } from 'config/constants';
import { CameraOutlined, CheckCircleFilled, CheckCircleOutlined, ContainerOutlined, DeleteFilled, DeleteOutlined, EditOutlined, EllipsisOutlined, HistoryOutlined, LogoutOutlined, MailOutlined, RedoOutlined, SwitcherOutlined } from '@ant-design/icons';
import RestoreAccount from 'components/modals/RestoreAccount';
import LogOut from 'components/modals/LogOut';
// import Movie from 'components/commonComponents/Movie';
import { Fade } from 'react-reveal';
import Banner from 'components/commonComponents/Banner';
import Poem from 'components/commonComponents/Poem';

const { TabPane } = Tabs;

const Settings = () => {
    const { userDetails, currentLang } = useSelector((store) => store.commonData);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [openLogOut, setLogOutOpen] = useState(false);
    const [openRestoreAccount, setOpenRestoreAccount] = useState(false);
    const [openDeleteAccount, setOpenDeleteAccount] = useState(false);
    const [openPswd, setOpenPaswd] = useState(false);
    const [openName, setOpenName] = useState(false);
    const [openEmail, setOpenEmail] = useState(false);
    const [openInvitation, setOpenInvitation] = useState(false)
    const [changeImg, setChangeImage] = useState({});
    const hiddenChangeImg = useRef(null);
    const [favourites, setFavourites] = useState([])
    const [expiryDate, setExpiryDate] = useState('')
    const [total, setTotal] = useState(1)

    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchFavorites();
        transformDate();
    }, [currentLang]);

    const handleOpenChangePaswd = () => {
        setOpenPaswd(true);
    };

    const handleClosePaswd = (value) => {
        setOpenPaswd(false);
    };

    const handleOpenChangeName = () => {
        setOpenName(true);
    };
    const handleCloseName = () => {
        setOpenName(false);
    };

    const handleOpenChangeEmail = () => {
        setOpenEmail(true);
    };
    const handleCloseEmail = () => {
        setOpenEmail(false);
    };

    const handleOpenDeleteAccount = () => {
        setOpenDeleteAccount(true);
    };
    const handleCloseDeleteAccount = () => {
        setOpenDeleteAccount(false);
    };

    const handleOpenRestoreAccount = () => {
        setOpenRestoreAccount(true);
    };

    const handleCloseRestoreAccount = () => {
        setOpenRestoreAccount(false);
    };

    const handleOpenInvitation = () => {
        setOpenInvitation(true);
    };

    const handleCloseInvitation = (value) => {
        setOpenInvitation(false);
    };

    const openUnsubscribe = () => {
        setShow(true);
    };

    const closeUnsubscribe = (value) => {
        setShow(false);
    };

    const handleProfileInput = (file) => {
        let files = file.target.files
        const reader = new FileReader();
        if (files.item(0)) {
            reader.readAsDataURL(files.item(0));
            reader.onload = (_event) => {
                setChangeImage({ img: reader.result, file: files.item(0) })
            }
        }
    }

    const handleOpenChangePicture = event => {
        hiddenChangeImg.current.click();
    };

    const changeProfilePic = async () => {
        const formData = new FormData();
        formData.append('profile_pic', changeImg.file)
        formData.append('appid', APP_ID)
        const res = await fetchData(restAPIs.changeProfPic(formData));
        if (res.statusCode === 200) {
            messageApi.open({
                type: 'success',
                content: res.errormessage,
            });
            let localDetails = JSON.parse(localStorage.getItem('userDetails'));
            localDetails.profile_pic = res.profile_pic + '&' + Math.random()
            dispatch({
                type: 'userDetails',
                value: localDetails
            });
            localStorage.setItem('userDetails', JSON.stringify(userDetails));
            setChangeImage({})

        } else {
            messageApi.open({
                type: 'error',
                content: res.errormessage,
            });
        }
    }

    const fetchFavorites = async () => {
        const formData = new FormData();
        formData.append('language', currentLang?.id)
        const res = await fetchData(restAPIs.favourites(formData));
        setFavourites(res.favourites)
        setTotal(res.total)
    }

    const handleOnclick = (item) => {
        navigate('/poem/' + item)
    }

    const removeFav = async (item) => {
        const formData = new FormData();
        formData.append('action', 'delete');
        formData.append('poemId', item.poemId);
        formData.append('appId', APP_ID);
        const res = await fetchData(restAPIs.addFavourite(formData));
        if (res.statusCode === 200) {
            setFavourites(res.favourites)
            messageApi.open({
                type: 'success',
                content: 'Poem Removed',
            });
        }
        else {
            messageApi.open({
                type: 'error',
                content: res.errormessage,
            });
        }
    }

    
    const transformDate = () => {
        const currentDate = new Date(userDetails?.expiryDate)
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
          ];

        const day = currentDate.getDate();
        const month = currentDate.getMonth()
        const year = currentDate.getFullYear();
        const formattedDate = `${day} ${monthNames[month]}, ${year}`;
        setExpiryDate(formattedDate)

    }


    return (
        <>
            <Banner title={t('settings')} />
            <div className='container __innerTopPad' dir={currentLang?.id === 1 ? 'rtl' : 'ltr'}>
                {contextHolder}
                <Tabs
                    tabPosition={'left'}
                    className='__tabs_outline'
                >
                    <TabPane
                        tab={<span>{t('settings')}</span>}
                        key="1"
                    >
                        <div className="profile">
                            <div className="prfImg">
                                {changeImg?.img ?
                                    <img src={changeImg?.img} alt={userDetails?.username} />
                                    :
                                    <img src={userDetails?.profile_pic} alt={userDetails?.username} />
                                }
                                {changeImg?.img && <a className='savePic' onClick={changeProfilePic}><CheckCircleFilled /></a>}
                            </div>
                            <div className="profCont">
                                <h3>{userDetails?.username}</h3>
                                <p style={{ marginBottom: '20px', textAlign: 'center' }}>{userDetails?.email}</p>
                                {userDetails && userDetails?.isPremium ?
                                    <>
                                        <button className='___subscriptioName'>{userDetails?.subscriptionName}</button>
                                        <p style={{ fontSize: '16px', textAlign: 'center', marginBottom: '15px' }}>Expires on {expiryDate}</p>
                                    </>
                                    : ''
                                }
                                <a style={{ margin: '0 10px' }} onClick={handleOpenChangeName}><EditOutlined style={{ margin: '0 10px' }} />{t('change_name')}</a>
                                <a style={{ margin: '0 10px' }} onClick={handleOpenChangeEmail}><MailOutlined style={{ margin: '0 10px' }} />{t('change_email')}</a>
                                <a style={{ margin: '0 10px' }} onClick={handleOpenChangePaswd}><EllipsisOutlined style={{ margin: '0 10px' }} />{t('change_password')}</a>
                                <a style={{ margin: '0 10px' }} onClick={handleOpenChangePicture}><CameraOutlined style={{ margin: '0 10px' }} />{t('edit_picture')}</a>
                                <input type="file" onChange={handleProfileInput} ref={hiddenChangeImg} style={{ display: 'none' }} />
                                {userDetails && !userDetails?.isPremium ? <a onClick={handleOpenInvitation} style={{ margin: '0 10px' }} ><ContainerOutlined style={{ margin: '0 10px' }} />{t('add_invitation_key')}</a> : ''}

                                {userDetails && !userDetails?.isPremium ? <NavLink to={'/subscribe'} end style={{ margin: '0 10px' }} ><SwitcherOutlined style={{ margin: '0 10px' }} />{t('subscribe')}</NavLink> : <a onClick={openUnsubscribe} style={{ margin: '0 10px' }} ><RedoOutlined style={{ margin: '0 10px' }} />{t('unsubscribe')}</a>}

                                {
                                    userDetails && (userDetails.markDeletion == 0) ?
                                        <a style={{ margin: '0 10px' }} onClick={handleOpenDeleteAccount}><DeleteOutlined style={{ margin: '0 10px' }} />{t('delete_account')}</a> :
                                        <a style={{ margin: '0 10px' }} onClick={handleOpenRestoreAccount}><HistoryOutlined style={{ margin: '0 10px' }} />{t('restore_account')}</a>
                                }
                                <a style={{ margin: '0 10px' }} onClick={() => setLogOutOpen(true)}><LogoutOutlined style={{ margin: '0 10px' }} />{t('sign_out')}</a>
                            </div>
                        </div>
                        <ChangePassword open={openPswd} onClose={handleClosePaswd} />
                        <ChangeName open={openName} onClose={handleCloseName} />
                        <ChangeEmail open={openEmail} onClose={handleCloseEmail} />
                        <AddInvitation open={openInvitation} onClose={handleCloseInvitation} />
                        <DeleteAccount open={openDeleteAccount} onClose={handleCloseDeleteAccount} />
                        <RestoreAccount open={openRestoreAccount} onClose={handleCloseRestoreAccount} />
                        <Unsubscribe open={show} onClose={closeUnsubscribe} />
                        <LogOut open={openLogOut} onClose={() => setLogOutOpen(false)} />
                    </TabPane>
                    <TabPane
                        tab={<span>{t('favourites')}</span>}
                        key="2"
                    >
                        {total === 0 && <p style={{fontSize:'18px', textAlign : 'center', marginTop: ' 10px'}}>{t('data_not_available')}.</p>}
                         <div className='__fav_grid_list'>
                            {
                                favourites && favourites?.map((item, idx) => {
                                    return (
                                        <Fade left duration={1000}>
                                            <div className='__single_st'>
                                                <Poem item={item} key={idx} handleOnClick={() => handleOnclick(item.poemId)} />
                                                <button className='__del-bt' onClick={() => removeFav(item)}><DeleteFilled /></button>
                                            </div>
                                        </Fade>
                                    )
                                })
                                }


                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        
                        

                    </TabPane>
                </Tabs>
            </div>
        </>
    );
};


export default Settings;
