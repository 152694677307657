// Core
import React from 'react';
// Styles
import './styles.scss';
import PropTypes from 'prop-types';


const Highlight = (props) => {
  const { highlight, text } = props;
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return <> {parts ? parts.map((part, i) =>
    <span key={i} style={part === highlight ? { fontWeight: 'bold', background: '#ffff0054' } : {}} >
      {part}
    </span>)
  : text} </>;

};

export default Highlight;

Highlight.propTypes = {
  text: PropTypes.any,
  highlight: PropTypes.any,
};
