
import { Routes, BrowserRouter, Route } from 'react-router-dom';

// Components
import Header from 'components/commonComponents/Header';

import Footer from 'components/commonComponents/Footer';
import Settings from 'components/pages/Settings';
import About from 'components/pages/About';
import Founder from 'components/pages/About/Founder';
import AppDetail from 'components/pages/About/AppDetail';
import Contact from 'components/pages/Contact';
import Policy from 'components/pages/StaticPages/Policy';
import Subscription from 'components/pages/Subscription';
import SubscribePayment from 'components/pages/SubscribePayment';
import Etisalat from 'components/pages/Etisalat';
import HelpAndSupport from 'components/pages/HelpAndSupport';
import SearchPage from 'components/pages/Search';
import HomePage from '../components/pages/HomePage';
import Poems from 'components/pages/Poems';
import Poets from 'components/pages/Poets';
import Letters from 'components/pages/Letters';
import ViewPoem from 'components/pages/Poems/ViewPoem';
import ViewPoet from 'components/pages/Poets/ViewPoet';
import Sitemap from 'components/pages/Sitemap';
import Thanks from 'components/pages/Thanks';

const AppRouter = () => {
  return (
    <>
      <BrowserRouter >
        <div className='__dark'>
          <Header />

          <div className="content-wrapper">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="*" element={<HomePage />} />

              <Route path="/poems" element={<Poems />} />
              <Route path="/poem/:poemId" element={<ViewPoem />} />

              <Route path="/poets" element={<Poets />} />
              <Route path="/poet/:poetId" element={<ViewPoet />} />

              <Route path="/letters" element={<Letters />} />

              <Route path="/about" element={<About />} />
              <Route path="/the-founder" element={<Founder />} />
              <Route path="/about/app/:appId" element={<AppDetail />} />
              <Route path="/contact" element={<Contact />} />

              <Route path="/privacy" element={<Policy id='59' />} />
              <Route path="/cookie" element={<Policy id='46' />} />
              <Route path="/terms" element={<Policy id='47' />} />
              <Route path='/help-and-support' element={<HelpAndSupport />} />

              <Route path="/settings" element={<Settings />} />

              <Route path="/subscribe" element={<Subscription />} />
              <Route path="/subscription-payment/:subId" element={<SubscribePayment />} />
              <Route path="/etisalat/:subId" element={<Etisalat />} />

              <Route path="/search" element={<SearchPage />} />

              <Route path="/thanks" element={<Thanks />} />

              <Route path="/sitemap" element={<Sitemap />} />

            </Routes>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    </>
  );
};
export default AppRouter;
