import {
  USER_DETAILS,
  SET_LANGUAGE,
} from 'config/actionTypes';
import initialState from './initialState';

const commonData = (state = initialState, action) => {
  switch (action.type) {
    case USER_DETAILS:
      return {
        ...state,
        userDetails: action.value
      };
    case SET_LANGUAGE:
      return {
        ...state,
        currentLang: action.value
      };
    default:
      return state;
  }
};
export default commonData;
