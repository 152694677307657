// Core
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Styles
import './styles.scss';
// icons

import Done from 'assets/images/done.png'
import { useSelector } from 'react-redux';
import Banner from 'components/commonComponents/Banner';

const Thanks = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userDetails, currentLang } = useSelector((store) => store.commonData);



  return (
    <div className='___thanks'>
      <Banner title='' />
      <div className="__sub_complete_full">
        <div className="__sub_complete_sec">
          <div className="container container-md">
            <div className="row">
              <div className="col-12 text-center">

                <h1 className="__sub_complete_head">
                  You have successfully subscribed!</h1>
                <p style={{
                  margin: 0,
                  marginTop: '25px',
                  fontSize: '16px',
                  fontWeight: '800'
                }}>Email</p>
                <h4 style={{ fontSize: '22px' }}>{userDetails?.email}</h4>
                <a style={{ fontSize: '16px', fontWeight: 'bold', borderBottom: '1px solid #ccc', cursor: 'pointer' }} onClick={() => navigate('/home')}>Click here to access the poems</a>

              </div>
            </div>
          </div>
        </div>

        <div className="__sub_complete_img_sec">
          <img src={Done} alt="" style={{ width: '100%' }} />


        </div>
      </div>
    </div>
  );
};

export default Thanks;