// Core
import React from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


// logo
import Visa from 'assets/images/visa_mastercard.png'
import Appstore from 'assets/images/app_store.png'
import Playstore from 'assets/images/google_play.png'
import Logo from 'assets/images/logo.jpg'

// Styles
import './styles.scss';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { FacebookOutlined, InstagramOutlined, TwitterOutlined, YoutubeOutlined } from '@ant-design/icons';
import Unsubscribe from 'components/modals/Unsubscribe';
import Button from '../Button';

import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import { APP_ID } from 'config/constants';
import { useEffect } from 'react';


const Footer = () => {
  const { userDetails, currentLang } = useSelector((store) => store.commonData);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [aboutData, setAboutData] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAboutData();
  }, [currentLang]);

  const fetchAboutData = async () => {
    const res = await fetchData(restAPIs.getAppInfo(APP_ID, currentLang?.id));
    setAboutData(res.appInfo);
  };


  const openUnsubscribe = () => {
    setShow(true);
  };

  const closeUnsubscribe = (value) => {
    setShow(false);
  };

  const gotoHome = () => {
    navigate('home')
  }
  return (
    <>
      <div className='__footer '>
        <div className='container'>
          <div className='row mb-3 justify-content-between'>
            <div className='col-md-4 __ars mb-2'>
              <div className='d-flex align-items-center' style={{ gridGap: '10px', fontSize: '22px', fontWeight: 500 }}>
                <img className='__logo' src={Logo} alt="Hawaya" onClick={() => gotoHome()} />
                {aboutData?.name}
              </div>
              <span style={{ fontSize: '20px' }}>{aboutData?.tagline}</span>
              <span className='__ft_abt_de' dangerouslySetInnerHTML={{ __html: aboutData?.description }}></span>
              <div className='d-flex align-items-center mt-2' style={{ gridGap: '10px', fontSize: '22px' }}>
                <a href="http://hawayaios.electronicvillage.org/" target="_blank" rel="noopener noreferrer"><img src={Appstore} alt="Appstore" style={{ width: '100%', height: '30px' }} /></a>
                <a href="http://hawayaandroid.electronicvillage.org/" target="_blank" rel="noopener noreferrer"><img src={Playstore} alt="Playstore" style={{ width: '100%', height: '30px' }} /></a>
              </div>
            </div>
            <div className='col-md-7'>
              <div className='row'>
                <div className='col-md-4 __foot_links'>
                  <NavLink to={'home'} end>{t('home')}</NavLink>
                  <NavLink to={'about'} end>{t('about_us')}</NavLink>
                  <NavLink to={'contact'} end>{t('contact_us')}</NavLink>
                </div>

                <div className='col-md-4 __foot_links'>
                  <NavLink to={'poems'} end>{t('poems')}</NavLink>
                  <NavLink to={'poets'} end>{t('poets')}</NavLink>
                  <NavLink to={'letters'} end>{t('letters')}</NavLink>
                </div>

                <div className='col-md-4 __foot_links'>
                  <NavLink to={'privacy'} end>{t('privacy_policy')}</NavLink>
                  <NavLink to={'cookie'} end>{t('cookie_policy')}</NavLink>
                  <NavLink to={'terms'} end>{t('terms_and_conditions')}</NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='__copywright'>
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-md-6'>
                Powered By <b>Electronic Village</b> © 2023 Hawaya
              </div>
              <div className='col-md-6 __social'>
                <div className='_pr'>
                  {(userDetails?.fullname && userDetails?.subscription != 0) ?
                    <Button className='mb-1' style={{ width: '100%', padding: '0px 20px', height: '30px', margin: 0, background: '#d3044a' }} handleOnClick={openUnsubscribe} label={t('unsubscribe')} />
                    : <NavLink className='__nav_subscribe' to={'subscribe'} end>{t('subscribe')}</NavLink>}
                </div>
                <img src={Visa} alt="" style={{ width: '100px' }} />
                <a ><FacebookOutlined /></a>
                <a><InstagramOutlined /></a>
                <a><YoutubeOutlined /></a>
                <a><TwitterOutlined /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Unsubscribe
        open={show}
        onClose={closeUnsubscribe}
      />
    </>
  );

};

export default Footer;
