// Core
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Styles
import './styles.scss';
import { useSelector } from 'react-redux';
import Login from 'components/modals/Login';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useEffect } from 'react';

import { Spin } from 'antd';
import { Fade } from 'react-reveal';
import Poem from 'components/commonComponents/Poem';
import Banner from 'components/commonComponents/Banner';
import Poet from 'components/commonComponents/Poet';

// icons

const SearchPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { userDetails, currentLang } = useSelector((store) => store.commonData);
  const [results, setResults] = useState('')
  const [isLoading, setLoading] = useState(false);
  const [isLastPage, setIsLastPage] = useState(true);
  const [isLoadingPage, setLoadingPage] = useState(false);
  const [page, setPage] = useState(1);

  const [searchParams] = useSearchParams();
  const [searchtext, setSearchtext] = useState('')

  const [searchIn, setSearchIn] = useState(searchParams.get('searchIn'))

  const handleCloseModel = () => {
    setShow(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setSearchIn(searchParams.get('searchIn'))
    fetchResults();
}, [currentLang, searchParams]);

  const fetchResults = async () => {
    setLoading(true)
    
    let res;
    if (searchParams.get('searchIn') == 1) {
      const formData = new FormData();
    formData.append('language', currentLang?.id)
    formData.append('page', 1)
    formData.append('searchText', btoa(unescape(encodeURIComponent(searchParams.get('searchText')))))
    formData.append('searchOption', searchParams.get('searchFor'))
      res = await fetchData(restAPIs.searchPoem(formData));
      setResults(res.data)
    }
    else {
      res = await fetchData(restAPIs.getPoetList(currentLang?.id, null, 1, searchParams.get('searchText'), searchParams.get('searchFor')));
      setResults(res.items)
    }
    setIsLastPage(res.isLastPage)
    setLoading(false)
  }

  const handleOnClickItem = (item) => {
    item.inappFree === 0 ? navigate('/poem/' + item.poemId+'?searchkey='+searchParams.get('searchText')) : userDetails ? userDetails.isPremium ? navigate('/poem/' + item.poemId+'?searchkey='+searchParams.get('searchText')) : navigate('/subscribe') : setShow(true)
  }

  const handlePageClick = () => {
    let curren = page
    setPage(curren + 1);
    fetchResultsPaginate(curren + 1)
  }
  const fetchResultsPaginate = async (page) => {
    setLoadingPage(true)
    const formData = new FormData();
    formData.append('language', currentLang?.id)
    formData.append('page', page)
    formData.append('searchText', btoa(unescape(encodeURIComponent(searchParams.get('searchText')))))
    formData.append('searchOption', searchParams.get('searchFor'))
    let res;
    if (searchParams.get('searchIn') == 1) {
      res = await fetchData(restAPIs.searchPoem(formData));
      setResults(results.concat(res.data))
    }
    else {
      res = await fetchData(restAPIs.getPoetList(currentLang?.id, null, page, searchParams.get('searchText'), searchParams.get('searchFor')));
      setResults(results.concat(res.items))
    }
    setIsLastPage(res.isLastPage)
    setLoadingPage(false)
  }

  return (
    <>
      <Banner title={t('search') + ' - ' + (searchParams.get('searchIn') == 1 ? t('poems') : t('poets'))} desc={searchParams.get('searchText')}/>
      <div className='container __innerTopPad' dir={currentLang?.id === 1 ? 'rtl' : 'ltr'}>

        <div className='row'>
          <div className='col-md-12'>
            {isLoading ?
              <div className='text-center'>
                <Spin className='__spin_color' size="large" tip="Loading" />
              </div>
              :
              <>
                <div className={searchParams.get('searchIn') == 1 ? '__poems_list' : '__poet_list'}>
                  {
                    results && results?.map((item, idx) => {
                      return (
                        <Fade bottom duration={2000}>
                          {searchParams.get('searchIn') == 1 ? <Poem item={item} key={idx} handleOnClick={() => handleOnClickItem(item)} searchkey={searchParams.get('searchText')}/>
                          : <Poet searchkey={searchParams.get('searchText')} item={item} key={idx} handleOnClick={() => navigate('/poet/' + item.poetId+'?searchkey='+searchParams.get('searchText'))} />
                          }
                        </Fade>
                      )
                    })}
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                {isLoadingPage ?
                  <div className='text-center mb-4 mt-2'>
                    <Spin className='__spin_color' size="large" tip="Loading" />
                  </div> : ''}</>
            }
          </div>
          {isLoading ? '' :

            <div className='__pagination' style={{ justifyContent: 'center' }}>
              {isLastPage ? '' : <button onClick={handlePageClick}>{t('more')} </button>}
            </div>
          }
          {isLoading ? '' :
            <div className='text-center' style={{ fontSize: '16px' }}>{isLastPage && results.length === 0 ? 'No Results' : ''}</div>}
        </div>
        <Login
          open={show}
          onClose={handleCloseModel}
        />
      </div>
    </>
  );
};

export default SearchPage;